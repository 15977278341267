a:link {
  text-decoration: none;
  color: inherit;
}

a {
  color: inherit;
}

#root, html, body, .PageBox {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #39393b;
  color: #dde0e1
}

.app {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}